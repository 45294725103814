import React,{useState,useEffect,useRef}  from "react";
import Layout from "../components/layout";
import FormGenerator from "../components/form/FormGenerator";
import List from "../components/form/List";
import styled from "styled-components";
import Data from "../config/DJs"
import Edit from "../components/form/Edit";
import {BaseSelect,BaseFormField,FormSubmit,BaseTextArea} from "../styles/Global"
import { GetList ,GetFeed} from "../utils/ApiFunctions";
import axios from "axios";
import mainjson from '../config/main';
import {ucfirst,GetUrlParams,MakeUrlParams} from "../utils/HelperFunctions"


const Header = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 14px 10px;
box-shadow: 1px 0 20px rgba(0,0,0,.08);
`

const Title = styled.div`
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-left:10px;
`
const Right = styled.div`
position:absolute;
right:25px;
`

const ListContainer = styled.div`
  padding-bottom:40px;
`;

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: block;
  margin-bottom: .5rem;
  font-weight: 400;
`;

const Table = styled.table`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  display: block;
  margin-bottom: .5rem;
  font-weight: 400;
  width:50vw;
  border-collapse: collapse;
  border-spacing: 0px;
`;

const ListTitleBold = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: block;
  margin-bottom: .5rem;
  font-weight: bold;

`;


const FirstTD = styled.td`
border: 1px solid black;
padding: 10px;
width:200px;
`;

const FirstTDBold = styled.td`
border: 1px solid black;
padding: 10px;
width:200px;
font-weight:bold;
`;

const TD = styled.td`
border: 1px solid black;
padding: 10px;
`;

const Pad = styled.div`
  padding-bottom:30px;
`;


const InputContainer = styled.div`
 
`;

const Select = styled(BaseSelect)`

`

const Top = styled.div`
padding:25px;

`

const Container = styled.div`
padding:25px;
background-color:white;
`

const TextArea = styled.textarea`
    width:300px;
    height:100px;
`

const ScrollContainer = styled.div`
position:absolute;
overflow:auto;
height:100%;
width:100%;
`

const StyledTextArea = styled(BaseTextArea).attrs({
})`
  height:100px;
  width:300px;
`

const StyledFormField = styled(BaseFormField).attrs({
})`
  width:300px;
`




export default function Page() {
    const [options, setOptions] = useState([]);
    const [text, setCurrentText] = useState(null);
    const input = useRef();
    const inputRef = useRef();
    const [value, setValue] = useState(0);
    const [value2, setValue2] = useState(0);
    const [imported, setImported] = useState(false);
    const [importing, setImporting] = useState(false);
    const [importasvalue, setimportasValue] = useState(0);
    const filenameRef = useRef();
    const [active, setActive] = useState(true);
    
    const [data, setData] = useState({});
    

  useEffect(() => {
}, []);    
    

function validate() {

}

async function send(){
    var r = confirm("Are you certain want to send this message to all users?");
    let success = false;
    if (r == true) {
      setActive(false);
        let body = {};
        let resultdata = {};
        body["CMSUser-Token"] = localStorage.getItem('token');
        body["CMSUser-Agent"] = navigator.userAgent;
        body["title"] = value;
        body["message"] = value2;
    
        let ret = false;
    
        await axios({
            method: "post",
            url: mainjson().path + "/insertpushes",
            data: body,
          })
        .then((response) => {
           if (response.data) {
               resultdata = response.data;
              if (resultdata.success == true) {
                success = true;
              }
              setActive(true);
              alert("Push message is sent");
              setValue("");
              setValue2("");
           }
        })
        .catch((error) => {
            alert(error);
            setActive(true);
        });
    
        return success;

    }
}


function changed(e){   
    let val = e.target.value;
    if (val.length > 65) {
        val = val.substr(0,65);
    }
    setValue(val);
}

function changed2(e){   
    let val = e.target.value;
    if (val.length > 240) {
        val = val.substr(0,240);
    }
    setValue2(val);
}


useEffect(() => {
    
}, [data]);    
    


  return (
    <Layout>
         <ScrollContainer>
        <Header>
            <Title>
                   Send message to users
            </Title>

        </Header>
        <Top>
        <Container>
       
        <ListContainer>
        <ListTitle>Message</ListTitle>
            <Table>
                <tr><td>Title</td><td>
                <StyledFormField type='text' onChange={changed} onBlur={validate} value={value||""} maxlength={ 65 }/>
                </td></tr>
                <tr><td>Message</td><td>
                <StyledTextArea type='text' onChange={changed2} onBlur={validate} value={value2||""}  maxlength={ 178}></StyledTextArea>
                </td></tr>
                {active &&
                <tr><td></td><td><FormSubmit onClick={send}>Send</FormSubmit></td></tr>
                }
            </Table>
            </ListContainer>
            </Container>
            </Top>
            </ScrollContainer>
    </Layout>
  );
}